.page-container-course {
  display: flex;
  justify-content: flex-start;
}

.list-container-course {
  margin-top: 100px;
  margin-right: 780px;
  flex: 1;
  padding-left: 85px;
}

.list-item-course {
border: 1px solid rgba(206, 212, 218, 1);
padding: 10px;
display: flex;
align-items: center;
justify-content: space-between;
background-color: #eef4fa;
}

.list-item-course div {
display: flex;
}

.list-item-course p {
margin: 0;
}

.list-item-course button {
padding: 5px 10px;
border: none;
background-color: none;
cursor: pointer;
font-family: roboto;
font-weight: 500;
}


.approval-list-button {
background-color: #0064b6;
color: white;
padding: 10px 10px;
border: none;
border-radius: 6px;
cursor: pointer;
font-family: roboto;
font-weight: 500;
font-size: 14px;
line-height: 18px;
}

.approval-list-button:hover {
background-color: #0056b3;
}

.approval-button-container {
display: flex;
padding-top: 50px;
justify-content: flex-end;
}

.empty-box-for-filling {
display: flex;
padding-top: 200px;
justify-content: flex-end;
}

.delete-button-container {
  display: flex;
  align-items: center;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding-top: 15px;
}

.book-button {
  background: none;
  border: none;
  padding-right: 50px;
  cursor: pointer;
  padding-top: 15px;
}

.header-id {
  font-size: 1.3rem;
  font-weight: bold;
}

.cell-id {
  font-size: 1.1rem; 
}

.header-name {
  font-size: 1.3rem;
  font-weight: bold;
}

.cell-name {
  font-size: 1.1rem;
  font-weight: bold;
}

.header-email {
  font-size: 1.3rem;
  font-weight: bold;
}

.cell-email {
  font-size: 1.1rem;
}

.custom-row-class {
  background-color: #eef4fa;
}

.header-id, .header-name, .header-email, .header-action {
  background-color: #eef4fa;
}

.MuiDataGrid-root .MuiDataGrid-filler {
  background-color: #eef4fa;
}
.login-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('../../public/images/police_login.webp');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.login-wrapper .swap-btn {
    position: absolute;
    top: 2vh;
    right: 3vw;
    z-index: 10;
    width: 12vw;
    height: 5vh;
    min-width: 100px;
    min-height: 30px;
    max-width: 150px;
    padding: 1vh 1vw;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 2vh;
    cursor: pointer;
    transition: all 0.2s ease;
    color: white;
    background: #3e5b6e;
}

.swap-btn.true {
    background: #77995f;
}

.swap-btn.true:hover {
    background: #5b783f;
}

.login-content {
    margin-top: 5vh;
    width: 80vw;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2vw;
}

.login-title {
    color: white;
    font-size: 3vw;
    margin-bottom: 2vh;
}

.login-content form {
    width: 100%;
}

.login-content .input-wrapper {
    width: 100%;
    margin: 2vh 0;
    position: relative;
    height: 6vh;
}

.login-content input,
.login-content button {
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 1.5vh 1vw;
    min-height: 30px;
    box-sizing: border-box;
}

.login-content input {
    background: #f3f3f5;
    height: 100%;
}

.login-content button {
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.login-content button:hover,
.login-content input:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.login-content .input-wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: #3e5b6e;
    transition: width 0.3s ease;
    border-radius: 0 0 5px 5px;
}

.login-content .input-wrapper:focus-within::after {
    width: 100%;
}

@media (max-width: 768px) {
    .login-wrapper .swap-btn {
        width: 15vw;
        height: 6vh;
        font-size: 2.5vh;
    }

    .login-content {
        width: 90vw;
    }

    .login-title {
        font-size: 5vh;
    }
}

.modules-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    overflow: auto;
}

.button1-container {
    display: flex;
}

.module-button1 {
    background-color: transparent;
    cursor: pointer;
    padding: 12px 30px;
    margin: 0 5px;
    font-weight: normal;
    text-decoration: none;
    position: relative;
    border: none;
    outline: none;
    font-size: 18px;
}

.module-button1:focus {
    outline: none;
}

.module-button1.active {
    font-weight: 500;
}

.module-button1.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #0072CE;
}

.underline1 {
    height: 1px;
    width: 31.1%;
    background-color: black;
    margin-top: 0px;
    bottom: 0; 
}

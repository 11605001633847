.login-btn {
    background: #77995f;
    width: 100%;
    height: 6vh;
    padding: 1.5vh 1vw;
    margin-top: 2vh;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.login-btn:hover:enabled {
    background: #5b783f;
}
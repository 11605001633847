.register-btn {
    background: #3e5b6e;
    width: 100%;
    height: 6vh;
    padding: 1.5vh 1vw;
    margin-top: 2vh;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.register-btn:hover:enabled {
    background: #2b3f4e;
}

select::-ms-expand {
    display: none;
}

.input-wrapper select {
    width: 100%;
    height: 6vh;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 1.5vh 1vw;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right 1vw center;
    background-size: 2vw;
}
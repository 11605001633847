.teaching-plan-box {
    margin-top: 50px;
    padding-left: 75px;
}

.information-plan {
    font-size: 30px;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 10px;
}

.text-link-plan {
    padding-top: 20px;
    font-size: 22px;
    padding-left: -10px;
    font-weight: normal;
}

.text-link-plan ul {
    padding-top: 30px;
    list-style-type: none;
}

.text-link-plan ul li {
    margin-bottom: 40px;
}

.text-link-plan ul li button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
    text-align: left;
}

.text-link-plan ul li button:focus {
    outline: none;
}

.text-link-plan ul li {
    display: block;
}
.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  overflow: auto;
  width: 100%;
  padding: 1rem;
}

.tabs-buttons-container {
  display: flex;
  position: relative; /* For the underline indicator */
}

.tab-button {
  background-color: transparent;
  cursor: pointer;
  padding: 12px 30px;
  margin: 0 5px;
  font-weight: normal;
  text-decoration: none;
  border: none;
  outline: none;
  font-size: 18px;
  position: relative; /* For the active state underline */
}

.tab-button:focus {
  outline: none;
}

.tab-button.active {
  font-weight: 500;
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #0072CE; /* Blue underline for active tab */
}

.tab-content {
  width: 100%; /* You might want to adjust this depending on your layout */
  padding-top: 20px;
}

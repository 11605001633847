.teachingplanbank-page {
    padding-left: 10%; 
    padding-top: 10%;
    margin: 0 auto;
    box-sizing: border-box;
}

.teachingplanbank-box-a {
    flex-grow: 1;
    width: auto;
    margin-right: 1521px;
}

.text {
    font-size: 20px;
    line-height: 35px;
    font-weight: 400;
    font-family: Roboto;
}

.teachingplanbank-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.teachingplanbank-box1 {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-top: 80px;
    margin-bottom: 20px;
    border: none;
}

.teachingplanbank-button1 {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    font-family: Roboto;
}

.teachingplanbank-button1 svg {
    margin-right: 15px;
    font-size: 28px;
    fill: #000; /* Icon color */
}

.teachingplanbank-label1,
.teachingplanbank-label2 {
    font-weight: 500;
    font-family: Roboto;
    font-size: 18px;
    padding-top: 30px;
}

.teachingplanbank-box2 {
    border-radius: 5px;
    border: none;
    outline: none;
    width: 100%;
    flex-grow: 1;
}

.teachingplanbank-box2-label {
    font-weight: bold;
    margin-bottom: 10px;
}

.teachingplanbank-buttons {
    display: flex;
    padding-top: 20px;
}

.teachingplanbank-buttons1,
.teachingplanbank-buttons2,
.teachingplanbank-buttons3 {
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 30%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.xxx1,
.xxx2,
.xxx3,
.xxx4,
.xxx5,
.xxx6,
.xxx7,
.xxx8,
.xxx9,
.xxx10,
.xxx11,
.xxx12 {
    display: flex;
    margin-right: 60px;
    align-items: center;
    justify-content: center;
}

.xxx1 {
    padding-right: 45px;
}

.xxx2 {
    padding-right: 10px;
}

.xxx4 {
    padding-right: 9px;
}

.xxx5 {
    padding-right: 20px;
}

.xxx8 {
    padding-right: 26px;
}

.xxx10 {
    padding-right: 35px;
}

.xxx11 {
    padding-right: 13px;
}

.xxx12 {
    padding-right: 5px;
}

.xxx1 span,
.xxx2 span,
.xxx3 span {
    font-size: 14px;
}

.empty-box-for-filling-list {
    padding-top: 50px;
}
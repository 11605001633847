.content {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-bottom: 60px;
    margin: 0px;
}

.navbar {
    background-color: #F7F8F9;
    opacity: 0.9;
    width: 100%;
    display: flex;
    align-items: stretch;
    height: 90px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
}

.navbar ul {
    list-style-type: none;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.logo-item {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    margin-right: auto;
    margin-left: 20px;
    height: 100%;
}

.logo-item img {
    display: block;
    width: 100%;
    height: auto;
}

.navbar ul li {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 20px;
}

.navbar ul li:first-child {
    margin-left: 0;
}


.navbar ul li a {
    text-decoration: none;
    color: #2D2926;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: Roboto;
    display: flex;
    align-items: right;
    justify-content: center;
    padding: 10px 30px;
    user-select: none;
}

.logout-button {
    margin-right: 30px;
}

.logout-button button {
    text-decoration: none;
    color: rgb(158, 0, 0);
    font-size: 18px;
    line-height: 28px;
    height: 100%;
    white-space: nowrap;
    display: flex;
    font-weight: 600;
    font-family: Roboto;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    user-select: none;
}

.logout-button:hover button {
    color: rgb(211, 0, 0);
}

.navbar ul li a.active {
    color: #00569C !important;
}

.navbar ul li a:hover {
    color: rgb(148, 148, 148);
}

.footer {
    background-color: #F7F8F9;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo {
    height: 46.46px;
    width: 44.28px;
    top: 65.27px;
    left: 694.36px;
    background-image: #F7F8F9;
}
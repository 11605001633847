.course-card {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 450px;
    background: linear-gradient(to top, #f3f3f3, #ffffff);
    position: relative;
}

.course-card-image {
    flex: 5;
    position: relative;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0;
    opacity: 0;
    transition: opacity 0.5s;
}

.image-overlay ul {
    list-style-type: circle;
    padding-left: 20px;
    margin-left: 0;
    width: calc(100% - 40px);
    box-sizing: border-box;
}

.image-overlay span {
    font-size: 0.85rem;
    font-weight: bold;
    color: black;
    text-align: center;
    margin: 0;
    padding-left: 20px;
    padding-bottom: 10px;
}

.course-card:hover .image-overlay {
    opacity: 1;
}

.course-card-list-item {
    color: black;
    font-size: 0.85rem;
    list-style-type: disc;
    cursor: pointer;
    transition: color 0.3s ease, text-decoration 0.3s ease;
    padding: 5px 0;
}

.course-card-list-item:hover {
    color: #7b99b1;
}

.course-card .course-card-image .image-overlay ul .course-card-list-item.disabled {
    color: grey;
    text-decoration: line-through;
    cursor: not-allowed;
    pointer-events: none;
}


.course-card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2;
    padding-top: 10px;
    text-align: center;
}

.course-card-title,
.course-card-info-button {
    margin: 0;
    padding: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-card-title {
    font-size: 1.5rem;
    font-weight: bold;

}

.course-card-info-button {
    width: 100%;
    background: rgb(95, 148, 95);
    outline: none;
    border: none;
}
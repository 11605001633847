.text-over-qrcode {
    padding-top: 200px;
    font-weight: bold;
    font-size: 32px;
    height: 30px;
    text-align: center;
}

.qrcode-container {
    position: relative;
    margin-top: 50px;
    text-align: center;
}
.courseinfo_background {
  width: 100%;
  padding-left: 90px;
  padding-top: 80px;
  margin-left: 0px;
  margin-top: 60px;
  height: 380px;
  background-image: url('../components/images/police_course_back.png');
  color: white;
  background-size: 100%;
  background-position: 0px -90px;
  z-index: -1;
}

.button3-container {
  display: flex;
  padding-left: 0px;
}

.module-button3 {
  background-color: transparent;
  cursor: pointer;
  padding: 12px 30px;
  margin: 0px 5px;
  text-decoration: none;
  font-weight: normal;
  position: relative;
  border: none;
  outline: none;
  font-size: 18px;
}

.module-button3:focus {
  outline: none;
}

.module-button3.active {
  font-weight: 500;
}

.module-button3.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #0072CE;
}

.underline3 {
  height: 1px;
  width: 34.41%;
  background-color: #C4C6C7;
  margin-left: 5px;
}

.box-d {
  padding-left: 84px;
  margin-top: 100px; 
}

.button2-container {
  display: flex;
}

.list-course-general-box-a ul {
  list-style-type: none;
  padding: 0;
  padding-left: 30px;
}

.list-course-general-box-a button:hover {
  color: white;
}

.list-course-general-box-a button {
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  font-weight: 600;
  padding-right: 200px;
  margin-bottom: 10px;
  transition: color 0.3s ease;
  margin-bottom: 20px;
}

.list-course-general-box-a button.selected {
  color: white;
}

.list-course-general-box-a button.selected:hover {
  color: white;
}

.list-course-general-box-b ul {
  list-style-type: none;
  padding: 0;
}

.list-course-general-box-b ul li span {
  font-weight: 600;
}

.list-course-general-box-b ul li {
  font-size: 20px;
  padding-bottom: 20px;
}

.list-course-general-box-b ul li:first-child {
  font-size: 32px;
  font-weight: bold;
}

.list-course-general-box-a {
  position: relative;
}

.list-course-button-imp {
  position: relative;
}

.course-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 250px;
}

.arrow-container {
  margin-left: 5px;
}

.edit-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: none;
}

.edit-button {
  border: none;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
  color: white;
  top: 0;
  position: absolute;
  right: 0;
}

.list-course-general-box-c {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 700px;
  position: relative;
}

.edit-button svg {
  width: 35px;
  height: 35px;
  stroke-width: 0.8px;
}
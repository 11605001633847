.list-container-course-planbank {
    margin-top: 100px;
    flex: 1;
  }

.table-container-planbank {
    padding-right: 800px;
}

.list-icon-button1 {
    background: none;
    border: none;
    cursor: pointer;
    padding-top: 15px;
    margin-right: 100px;
}
  
.list-icon-button2 {
    background: none;
    border: none;
    padding-right: 50px;
    cursor: pointer;
    padding-top: 15px;
}
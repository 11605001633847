.course-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(298px, max-content));
    gap: 2rem;
    padding: 1rem;
    justify-content: center;
    align-items: start;
}

.course-wrapper {
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
    padding: 0;
    text-align: center;
}
/* src/pages/CreateCoursePage.css */

.create-course-wrapper {
  max-width: 600px;
  margin: auto;
  padding: 2em;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.create-course-form {
  display: flex;
  flex-direction: column;
}

.input-wrapper {
  margin-bottom: 1em;
}

.input-wrapper label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.input-wrapper input {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  
}

.submit-btn {
  padding: 0.75em 1.5em;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 1em;
}

.success {
  color: green;
  margin-top: 1em;
}

.teaching-page {
  display: flex;
  flex-direction: column;
  padding: 5%;
  margin: auto;
  max-width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.box-a {
  display: flex;
  flex-direction: row; /* Align children in a row */
  padding: 2%;
  margin-right: auto;
}

.left-box-a {
  flex-grow: 1;
  width: 20%;
  padding: 2%; /* Adjust padding for better spacing */
}

.right-box-a {
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%; /* Adjust padding for better spacing */
}

.create-course-box-a {
  margin: 0 auto; /* Center the box */
  margin-top: -60px; /* Adjust margin for positioning */
  border: 2px solid rgb(0, 114, 206);
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 45px;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.icon-box-a {
  margin-right: 6px;
  padding-left: 12px;
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;
}

.create-course-text {
  margin-left: 2px;
  font-size: 1em; /* Use relative units */
  font-weight: 600;
  font-family: Roboto, sans-serif; /* Add a fallback font */
}

.icon-box-a svg {
  width: 28px;
  height: 28px;
  stroke-width: 0.3px;
}

.text {
  font-size: 1.25em; /* Use relative units */
  line-height: 1.75em;
  font-weight: 400;
  font-family: Roboto, sans-serif; /* Add a fallback font */
  word-wrap: break-word; /* Ensure long words break to fit within the container */
}

.box-b {
  margin-top: 2em; /* Use relative units */
  margin-right: auto;
  padding-left: 2em; /* Use relative units */
  max-width: 100%;
}

.button2-container {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line if needed */
}

.module-button2 {
  background-color: transparent;
  cursor: pointer;
  padding: 12px 30px;
  margin: 5px; /* Adjust margin for better spacing */
  font-weight: normal;
  text-decoration: none;
  position: relative;
  border: none;
  outline: none;
  font-size: 1em; /* Use relative units */
}

.module-button2:focus {
  outline: none;
}

.module-button2.active {
  font-weight: 500;
}

.module-button2.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #0072CE;
}

.underline2 {
  height: 1px;
  width: 97%;
  background-color: #C4C6C7;
  margin-left: 5px;
  margin-top: 10px; /* Add margin to separate underline from content */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
  .box-a {
      flex-direction: column; /* Stack elements vertically */
      padding: 5%; /* Adjust padding */
  }

  .right-box-a {
      padding-left: 5%; /* Adjust padding for smaller screens */
  }

  .create-course-box-a {
      margin: 20px auto; /* Center and add margin for spacing */
      width: 80%; /* Adjust width for smaller screens */
  }

  .box-b {
      margin-top: 2em; /* Adjust margin */
      padding-left: 5%; /* Adjust padding */
  }

  .module-button2 {
      padding: 10px 20px; /* Adjust padding */
      font-size: 0.875em; /* Adjust font size for smaller screens */
  }

  .icon-box-a svg {
      width: 24px; /* Adjust icon size */
      height: 24px;
  }
}

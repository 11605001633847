.page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container {
    width: 100%;
    height: 600px;
    background: url('../../public/images/home_background.webp') lightgray no-repeat center center;
    opacity: 0.925;
    background-size: cover;
    position: relative;
}

.arrow-container {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 96px;
    height: 96px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-arrow {
    width: 48px;
    height: 48px;
    background: url('../../public/images/keyboard_double_arrow_right.svg') no-repeat center center;
    background-size: contain;
}

.arrow-container:hover .image-arrow {
    animation: jump 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes jump {
    0%, 100% { 
        transform: translateY(0); 
    }
    75% { 
        transform: translateY(-20px);
    }
}


.image-arrow:hover {
    transition: transform 0.3s ease-in-out;
    transform: translateY(-5px);
}

.main-content {
    margin-top: 100px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    justify-content: center;
    align-items: center;
}

.text-section,
.image-section {
    position: relative;
    width: 400px;
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.image-section {
    border-radius: 5px;
}

.image-section.right {
    background-image: url('../../public/images/home_image1.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-section.left {
    background-image: url('../../public/images/home_image2.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}


@keyframes scroll-background {
    0% {  background-position: 0% 50%; }
    100% { background-position: 200% 50%; }
}

span.home-title {
    font-weight: 1000;
    font-size: 1.7em;
    background: linear-gradient(270deg, #7b99b1, #3e5b6e, #00263ecb, #3e5b6e, #7b99b1);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: scroll-background 25s linear infinite;
}

span.home-title:hover {
    animation-play-state: paused;
}

span.ajour2 {
    font-weight: 800;
    color: #00263e;
}

.image-banner {
    width: 100%;
    height: 300px;
    background-image: url('../../public/images/home_banner.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.image-banner .image-overlay {
    width: 100%;
    height: 100%;
    background: #0E1C2AE5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-banner .banner-text {
    color: white;
    font-size: 1.5em;
    font-weight: 700;
}

.sub-content {
    margin-top: 100px;
    margin-bottom: 100px;
}

.sub-content p {
    margin-top: 50px;
    text-align: center;
    font-size: 1em;
    font-weight: 700;
}
.modules-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        background: #FFFFFF;
        width: 80%;
    }
    
    .module-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));
        gap: 2rem;
        width: 100%;
        max-width: 1440px;
        margin-top: 2rem;
    }
    
    .module-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }
    
    .module-image {
        width: 100%;
        height: auto;
    }
    
    .module-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #2D2926;
        margin: 1rem 0;
    }
    
    .module-action {
        background: #E5F1FA;
        width: 100%;
        padding: 1rem;
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
    }
